import React, { Children } from "react";
import { Builder, withChildren } from "@builder.io/react";
import { useBreakpoint } from "@marbletech/hooks";
import Ticker from "framer-motion-ticker";

import { PlaceHolder } from "../PlaceHolder";

const spaceBetweenOptions = ["8", "16", "24", "32"];

import { interactiveElementsComponentsNames } from "../consts";

import type { AutoplayCarouselProps } from "./AutoplayCarousel.types";

export const AutoplayCarousel = ({
  children,
  mobileGap,
  desktopGap,
  duration = 40,
}: AutoplayCarouselProps) => {
  const childrenArr = Children.toArray(children);
  const { mobile } = useBreakpoint();
  const gap = mobile ? mobileGap : desktopGap;

  return children ? (
    <Ticker duration={duration}>
      {React.Children.map(childrenArr, (card, index) => (
        <div
          key={index}
          style={{
            marginLeft: `${gap}px`,
          }}
        >
          {card}
        </div>
      ))}
    </Ticker>
  ) : (
    <PlaceHolder>Insert Elements Inside</PlaceHolder>
  );
};

const AutoplayCarouselWithChildren = withChildren(AutoplayCarousel);

Builder.registerComponent(AutoplayCarouselWithChildren, {
  name: interactiveElementsComponentsNames.AUTOPLAY_CAROUSEL,
  inputs: [
    {
      name: "desktopGap",
      friendlyName: "Gap between slides (desktop)",
      type: "string",
      defaultValue: 24,
      enum: spaceBetweenOptions,
    },
    {
      name: "mobileGap",
      friendlyName: "Gap between slides (mobile)",
      type: "string",
      defaultValue: 24,
      enum: spaceBetweenOptions,
    },
  ],
});
