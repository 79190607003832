export const ClickTypes = {
  Link: "link",
  Icon: "icon",
  Image: "image",
  Text: "text",
  Interaction: "inteaction",
  Expand: "expand",
  Collapse: "collapse",
  Click: "click",
  Swipe: "swipe",
} as const;

export const interactiveElementsComponentsNames = {
  AUTOPLAY_CAROUSEL: "AutoplayCarousel",
  FAQS: "Faqs",
  ROTATING_TEXT_BUTTON: "Rotating Text Button",
  SINGLE_SLIDER: "SingleSlider",
  SLIDER: "Slider",
  TRUSTPILOT: "Trustpilot",
  VIDEO_DIALOGUE: "Video Dialogue",
  TRUSTPILOT_REVIEWS_WIDGET_GRID: "TrustpilotReviewsWidgetGrid",
} as const;

export const navigationComponentsNames = {
  NAVBAR: "Navbar",
  LINK: "Link",
  BUTTON: "Button",
  ICON_BUTTON: "IconButton",
} as const;
